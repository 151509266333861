
import { useI18n } from 'vue-i18n'
import { defineComponent, computed } from 'vue'
import ItemKeywordSearch from '@/components/organisms/ItemKeywordSearch.vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'Main',
  components: {
    ItemKeywordSearch,
  },
  props: {
    keyword: {
      type: String,
      default: '',
    },
    categoryCode: {
      type: String,
      default: '',
    },
    viewRestricted: {
      type: String,
      default: '',
    },
    pageNo: {
      type: String,
      default: '1',
    },
  },
  setup () {
    const i18n = useI18n()
    const lang = i18n.locale
    const route = useRoute()
    const inputKeyword = computed(() => route.query.keyword)

    const places = [
      { value: 'internet', label: i18n.t('mainSearch.accessRestriction.internet') },
      { value: 'ooc', label: i18n.t('mainSearch.accessRestriction.ooc') },
      { value: 'inlibrary', label: i18n.t('mainSearch.accessRestriction.inlibrary') },
    ]

    return {
      inputKeyword,
      places,
      lang,
    }
  },
})
